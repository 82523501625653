import React, { useEffect, useState } from "react";
import {
	User,
	Match,
	PaymentMethod,
	Subscription,
	AdminTask,
	Grandpa,
	Onboarding,
	AdminTaskScheme,
	SubscriptionScheme,
	UserScheme,
	CoorporateScheme,
	ResidenceScheme,
	MatchScheme,
	PaymentMethodScheme,
	ErrorScheme,
	ActivityRenderScheme,
	Dash,
} from "client-v2";
import styled from "styled-components";
import Row from "../../../components/Containers/Row";
import Col from "../../../components/Containers/Col";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import {
	Phone,
	MoreHorizontal,
	Play,
	Pause,
	Trash,
	MessageCircle,
	GraduationCap,
	PhoneOff,
} from "lucide-react";
import { Button, Modal, Menu, ColorV2 } from "@adoptaunabuelo/react-components";
import Spinner from "../../../components/Spinner/Spinner";
import AdminBar from "../../../components/Nav/AdminBar";
import SectionMain from "./SectionMain";
import SectionInfo from "./SectionInfo";
import SectionCall from "./SectionCall";
import SectionHistory from "./SectionHistory";
import SectionEmergencyContact from "./SectionEmergencyContact";
import SectionNotes from "./SectionNotes";
import SectionMatch from "./SectionMatch";
import SectionPreferences from "./SectionPreferences";
import SectionBiography from "./SectionBiography";
import SectionSubscription from "./SectionSubscription";
import SectionPaymentMethod from "./SectionPaymentMethod";
import SectionTickets from "./SectionTickets";
import PauseModal from "../../../components/Modals/PauseModal";
import CancelationModal from "../../../components/Modals/CancelationModal";

const Container = styled.div`
	display: flex;
	width: -webkit-fill-available;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const BarButtonView = styled.div`
	display: flex;
	align-items: center;
`;

const Profile = (props: Props) => {
	const dispatch = useDispatch();
	const { setProfileFunction, setLoading, setCallUser, setError } =
		bindActionCreators(actionsCreators, dispatch);
	const profileFunction = useSelector((state: State) => state.profile);
	const currentUser = useSelector((state: State) => state.currentUser);
	const callUser = useSelector((state: State) =>
		state.call ? state.call.user : undefined
	);
	const loading = useSelector((state: State) => state.modal.loading);
	const role = useSelector((state: State) => state.role);

	const navigate = useNavigate();
	const [localLoading, setLocalLoading] = useState(false);
	const [user, setUser] = useState<UserScheme | undefined>(undefined);
	const [corporate, setCorporate] = useState<CoorporateScheme | undefined>(
		undefined
	);
	const [residence, setResidence] = useState<ResidenceScheme | undefined>(
		undefined
	);
	const [activityArray, setActivityArray] = useState<ActivityRenderScheme[]>(
		[]
	);
	const [notesArray, setNotesArray] = useState<ActivityRenderScheme[]>([]);
	const [matchArray, setMatchArray] = useState<MatchScheme[]>([]);
	const [subscription, setSubscription] = useState<
		SubscriptionScheme | undefined
	>(undefined);
	const [paymentMethods, setPaymentMethods] = useState<
		PaymentMethodScheme[] | undefined
	>(undefined);
	const [adminTasks, setAdminTasks] = useState<AdminTaskScheme[]>([]);
	const [userOptions, setUserOptions] = useState<any[]>([]);
	const [showPauseModal, setShowPauseModal] = useState(false);
	const [showCancelationModal, setShowCancelationModal] = useState(false);
	const [showModal, setShowModal] = useState<
		| "verify-profile"
		| "necessity-profile"
		| "shutdown"
		| "remove-necessity-profile"
		| "training"
		| "notValidLead"
		| undefined
	>(undefined);

	useEffect(() => {
		setProfileFunction({ launch: true, function: "all" });
	}, []);

	useEffect(() => {
		const userId = new URLSearchParams(window.location.search).get("id");
		if (profileFunction.launch && userId) {
			get(userId);
		}
	}, [profileFunction]);

	const generateUserOptions = (user: UserScheme) => {
		let tmpArray = [];
		if (user.type === "grandpa") {
			if (user.state === "shutdown") {
				tmpArray.push({
					id: "active",
					label: "Activar",
					icon: <Play height={20} width={20} />,
				});
			}
			if (user.state === "paused") {
				tmpArray.push({
					id: "remove-paused",
					label: "Quitar pausa",
					icon: <Play height={20} width={20} />,
				});
			}
			if (user.state !== "shutdown" && user.state !== "paused") {
				tmpArray.push({
					id: "paused-profile",
					label: "Pausar",
					icon: <Pause height={20} width={20} />,
				});
			}
			if (user.state !== "shutdown" && user.state !== "paused") {
				tmpArray.push({
					id: "shutdown",
					label: "Dar de baja",
					icon: <Trash height={20} width={20} />,
				});
			}
		} else if (user.type === "volunteer") {
			tmpArray.push({
				id: "notValidLead",
				label: "Bloquear llamadas comerciales",
				icon: (
					<PhoneOff
						height={20}
						width={20}
						color={ColorV2.border.red}
					/>
				),
			});
		}
		if (role.user.set.banned) {
			if (user.state === "banned") {
				tmpArray.push({
					id: "training",
					label: "Repetir test de personalidad",
					icon: <GraduationCap height={20} width={20} />,
				});
			}
		}
		setUserOptions(tmpArray);
	};

	const get = async (userId: string) => {
		if (profileFunction.function === "all") {
			setLoading(true);
			if (window.location.pathname === "/abuelos/perfil") {
				Dash.getGrandpa(userId)
					.then((result) => {
						setUser(result.data.User);
						setResidence(result.data.User.Residence);
						setMatchArray(result.data.Matches);
						setNotesArray(result.data.notes);
						setActivityArray(result.data.history);
						generateUserOptions(result.data.User);
						setLoading(false);
					})
					.catch((e: ErrorScheme) => {
						setLoading(false);
						setError(true, e.message);
					});
			} else {
				Dash.getVolunteer(userId)
					.then(async (result) => {
						setUser(result.data.User);
						setCorporate(result.data.User.Coorporate);
						setResidence(result.data.User.Residence);
						setActivityArray(result.data.history);
						setAdminTasks(result.data.AdminTasks);
						setMatchArray(result.data.Matches);
						setPaymentMethods(result.data.PaymentMethods);
						setSubscription(result.data.Subscription);
						setNotesArray(result.data.notes);

						generateUserOptions(result.data.User);
						setLoading(false);
					})
					.catch((e: ErrorScheme) => {
						setLoading(false);
						setError(true, e.message);
					});
			}
		} else if (user) {
			const functionsToHandle = profileFunction.functions || [
				profileFunction.function,
			];

			functionsToHandle.forEach(async (func: any) => {
				if (func === "corporate") {
					setCorporate(profileFunction.data.corporate);
				} else if (func === "residence") {
					setResidence(profileFunction.data.residence);
				} else if (func === "activity") {
					getActivity(
						user,
						profileFunction.data ? profileFunction.data.page : 0,
						profileFunction.data
							? profileFunction.data.type
							: undefined
					)
						.then((result) => {
							const array = result.data.filter(
								(item) => item.type !== "note"
							);
							if (array.length > 0) {
								if (
									profileFunction.data &&
									profileFunction.data.remove
								) {
									setActivityArray(array);
								} else {
									setActivityArray([
										...activityArray,
										...array,
									]);
								}
							}
						})
						.catch((error: ErrorScheme) => {
							setError(true, error.message);
						});
				} else if (func === "notes") {
					getNotes(user)
						.then((result) => {
							setNotesArray(result.data);
						})
						.catch((error: ErrorScheme) => {
							setError(true, error.message);
						});
				} else if (func === "match") {
					const result = await getMatches(user, false);
					setMatchArray(result.data);
				} else if (func === "old-match") {
					const result = await getMatches(user, true);
					setMatchArray(result.data);
				} else if (func === "subscription") {
					const result = await getSubscription(user);
					if (result && result.data.length > 0) {
						const tempSubs = result.data[0];
						setSubscription(tempSubs);
					}
				} else if (func === "payment-method") {
					const result = await getPaymentMethods(user);
					if (result) {
						setPaymentMethods(result.data);
					}
				} else if (func === "admin-task") {
					getAdminTask(
						user,
						profileFunction.data.limit,
						profileFunction.data.state
					)
						.then((result) => {
							setAdminTasks(result.data);
						})
						.catch((error: ErrorScheme) => {
							setError(true, error.message);
						});
				}
			});

			setProfileFunction({ launch: false, function: "all" });
		}
	};

	const getActivity = async (
		user: UserScheme,
		page: number,
		type?: string
	) => {
		return User.getHistory(user.objectId, {
			type: type,
			page: page,
			limit: 10,
		});
	};

	const getNotes = async (user: UserScheme) => {
		return User.getHistory(user.objectId, { type: "note" });
	};

	const getMatches = async (user: UserScheme, hidden: boolean) => {
		return Match.get({
			volunteerId: user.type === "grandpa" ? undefined : user.objectId,
			grandpaId: user.type === "grandpa" ? user.objectId : undefined,
			hidden: hidden ? undefined : false,
			include: user.type === "grandpa" ? ["Volunteer"] : ["Grandpa"],
		});
	};

	const getPaymentMethods = async (user: UserScheme) => {
		if (user.type === "volunteer") {
			return PaymentMethod.get({
				userId: user.objectId,
			});
		}
	};

	const getSubscription = async (user: UserScheme) => {
		if (user.type === "volunteer") {
			return Subscription.get({
				userId: user.objectId,
				state: ["active", "trialing", "paused"],
				include: ["Product"],
			});
		}
	};

	const getAdminTask = async (
		user: UserScheme,
		limit: number,
		state?: string[]
	) => {
		return AdminTask.get({
			toId: user.objectId,
			state: state as any,
			limit: limit,
		});
	};

	const onUserChanged = (user: UserScheme) => {
		setUser(user);
	};

	const setNotValidLead = async () => {
		if (user) {
			setLocalLoading(true);
			User.set(user.objectId, {
				internalData: {
					notValidLead: "aua",
				},
			})
				.then((result) => {
					onUserChanged(result.data);
					setShowModal(undefined);
				})
				.catch((error: ErrorScheme) => {
					setShowModal(undefined);
					setError(true, error.message);
				});
		}
		setLocalLoading(false);
	};

	const shutdown = async (motivation: { id: string; label: string }) => {
		if (user) {
			setLocalLoading(true);
			Grandpa.remove(user.objectId, {
				motivation: motivation,
				adminId: currentUser.objectId,
			})
				.then((grandpa) => {
					onUserChanged(grandpa.data);
					setShowCancelationModal(false);
					setLocalLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLocalLoading(false);
				});
		}
	};

	const repeatTest = async () => {
		if (user) {
			setLocalLoading(true);
			Onboarding.remove(user.objectId, {
				adminId: currentUser.objectId,
			})
				.then((user) => {
					onUserChanged(user.data);
					setShowModal(undefined);
					setLocalLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLocalLoading(false);
				});
		}
	};

	const userOptionClick = (item: any) => {
		if (item.id === "create-task")
			navigate("/tareas/registro", {
				state: {
					user: user,
				},
			});
		else if (item.id === "paused-profile") setShowPauseModal(true);
		else if (item.id === "remove-paused") setShowPauseModal(true);
		else if (item.id === "shutdown") setShowCancelationModal(true);
		else setShowModal(item.id);
	};

	return loading ? (
		<Spinner />
	) : (
		<Container>
			{user && (
				<>
					<PauseModal
						isVisible={showPauseModal}
						user={user}
						onClose={() => setShowPauseModal(false)}
						onSave={(user) => {
							setShowPauseModal(false);
							onUserChanged(user as UserScheme);
						}}
					/>
					<CancelationModal
						isVisible={showCancelationModal}
						loading={localLoading}
						options={[
							{
								id: "dead",
								label: "Fallecimiento",
							},
							{
								id: "out-residence",
								label: "Baja de la residencia",
							},
							{
								id: "lost_capabilities",
								label: "Pérdida de capacidades",
							},
							{
								id: "adopta",
								label: "No ha tenido una buena experiencia",
							},
							{
								id: "phone-error",
								label: "Error de teléfono",
							},
							{
								id: "no-participation",
								label: "No interés en participar",
							},
							{
								id: "no-answer",
								label: "Nunca nos contesta",
							},
						]}
						onClose={() => setShowCancelationModal(false)}
						onSave={(motivation) => {
							if (motivation) shutdown(motivation);
						}}
					/>
					<Modal
						title={
							showModal === "training"
								? "Repetir test de personalidad"
								: showModal === "notValidLead"
								? "Bloquear llamada comercial"
								: ""
						}
						subtitle={
							showModal === "training"
								? "Con esta acción permitirás al usuario repetir el test de personalidad desde el principio"
								: showModal === "notValidLead"
								? "Al bloquear las llamadas comerciales a un usuario estás indicando que no desea recibir más llamadas, tanto nuestras como de proveedores, con motivos comerciales. Sin embargo, sus datos siguen estando disponibles para llamadas relacionadas con el voluntariado."
								: ""
						}
						isVisible={showModal ? true : false}
						onClose={() => setShowModal(undefined)}
						buttonProps={{
							children: "Aceptar",
							loading: localLoading,
							onClick:
								showModal === "training"
									? repeatTest
									: showModal === "notValidLead"
									? setNotValidLead
									: () => null,
						}}
					/>
					<AdminBar
						style={{ marginBottom: 16 }}
						title="Detalle"
						goBack={() => navigate(-1)}
						RightBar={
							<BarButtonView>
								{role.user.whatsapp && (
									<Button
										icon={
											<MessageCircle
												height={20}
												width={20}
											/>
										}
										size={"small"}
										style={{ backgroundColor: "#25D366" }}
										disabled={callUser}
										onClick={() => {
											window.open(
												"whatsapp://send?phone=" +
													user.phone
											);
										}}
									>
										WhatsApp
									</Button>
								)}
								{role.user.call && (
									<Button
										icon={<Phone height={20} width={20} />}
										size={"small"}
										style={{ marginLeft: 8 }}
										disabled={callUser}
										onClick={() => setCallUser(user)}
									>
										Llamar
									</Button>
								)}
								<Menu
									id="user-options"
									icon={
										<MoreHorizontal
											height={20}
											width={20}
										/>
									}
									options={userOptions}
									style={{
										marginLeft: 12,
										backgroundColor: "white",
										borderRadius: 40,
									}}
									menuStyle={{
										top: 40,
										right: 0,
										width: "max-content",
									}}
									position={"bottom-left"}
									onClick={(option) =>
										userOptionClick(option)
									}
								/>
							</BarButtonView>
						}
					/>
					<Row style={{ width: "100%" }}>
						<Col style={{ width: "33%" }}>
							<SectionMain
								user={user}
								residence={residence}
								corporate={corporate}
								admin={currentUser}
								onUserChanged={onUserChanged}
							/>
							<SectionInfo
								user={user}
								onUserChanged={onUserChanged}
							/>
							<SectionPreferences
								user={user}
								onUserChanged={onUserChanged}
							/>
							<SectionBiography
								user={user}
								onUserChanged={onUserChanged}
							/>
						</Col>
						<Col
							style={{
								width: "33%",
								marginLeft: 16,
								marginRight: 16,
							}}
						>
							<SectionCall
								user={user}
								onUserChanged={onUserChanged}
							/>
							<SectionHistory
								user={user}
								activityArray={activityArray}
							/>
						</Col>
						<Col style={{ width: "33%" }}>
							<SectionMatch
								user={user}
								admin={currentUser}
								matchArray={matchArray}
							/>
							<SectionEmergencyContact
								user={user}
								onUserChanged={onUserChanged}
							/>
							{user.type === "volunteer" && (
								<>
									<SectionTickets
										adminTasks={adminTasks}
										user={user}
									/>
								</>
							)}
							<SectionSubscription
								user={user}
								subscription={subscription}
								product={subscription?.Product}
								paymentMethods={paymentMethods}
								onUserChanged={onUserChanged}
							/>
							<SectionPaymentMethod
								user={user}
								paymentMethods={paymentMethods}
								onUserChanged={onUserChanged}
							/>
							<SectionNotes user={user} notesArray={notesArray} />
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};

export default Profile;
export interface Props {}
