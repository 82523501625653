import { ActionType } from "../actions-types/index";
import { Dispatch } from "redux";
import { Action } from "../actions/index";
import { AdminTaskScheme, UserScheme } from "client-v2";

export const setCurrentUser = (user: UserScheme | null) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_CURRENT_USER,
			data: user,
		});
	};
};

export const setCallUser = (
	user: UserScheme | null,
	data?: {
		adminTask: AdminTaskScheme;
	}
) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_CALL_USER,
			data: {
				user: user,
				...data,
			},
		});
	};
};

export const setProfileFunction = (data: {
	launch: boolean;
	function?: string; 
	functions?: string[];
	data?: Object;
}) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_PROFILE_FUNCTION,
			data: data,
		});
	};
};



export const setCoorporateProfileFunction = (data: {
	launch: boolean;
	function: string;
	data?: Object;
}) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_COORPORATE_PROFILE_FUNCTION,
			data: data,
		});
	};
};

export const setResidenceProfileFunction = (data: {
	launch: boolean;
	function: string;
	data?: Object;
}) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_RESIDENCE_PROFILE_FUNCTION,
			data: data,
		});
	};
};

export const setLoading = (isVisible: boolean) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_LOADING,
			data: {
				loading: isVisible,
			},
		});
	};
};

export const setVolunteerTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_VOLUNTEER_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setGrandpaTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_GRANDPA_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setTaskTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_TASK_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setCoorporateTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_COORPORATE_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setResidenceTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_RESIDENCE_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setEventTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_EVENT_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setError = (isVisible: boolean, text: string) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_ERROR,
			data: {
				error: {
					isVisible: isVisible,
					text: text,
				},
			},
		});
	};
};

export const setRole = (role: Object) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_ROLE,
			data: role,
		});
	};
};

export const setAutodial = (
	play: boolean,
	list: Array<{
		user: UserScheme;
		adminTask: Omit<AdminTaskScheme, "priority"> & {
			priority: {
				lucide: any;
				id: string;
				title: string;
				icon: string;
				color: string;
			};
		};
	}>
) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_AUTODIAL,
			data: {
				play,
				list,
			},
		});
	};
};

export const setAmbassadorTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_AMBASSADOR_TABLE_FILTERS,
			data: filters,
		});
	};
};

export const setSalesTableFilters = (filters: any) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_SALES_TABLE_FILTERS,
			data: filters,
		});
	};
};
